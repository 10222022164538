import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, md } from '../common'

export default () => (
  <div css={`background-color: ${colors.greyLight};`}>
  <Wrap>
  <h1 css={`margin:0;`}>GETTING ENGAGED?</h1>
  <div css={`display:flex;justify-content:space-around;${md}{flex-direction:column;justify-content:center;}`} >
    <Card to="/products/engagement">
      <img src="https://s3.amazonaws.com/underwoods/small_engagement.jpg" alt ="fancy ring" />
      <p>Engagement Rings »</p>
    </Card>

    <Card to="/designer/benchmark">
      <img src="https://s3.amazonaws.com/underwoods/small_weddingbands.jpg" alt ="nice watch" />
      <p>Shop Men's Wedding Bands »</p>
    </Card>

    <Card to="/registry">
      <img src="https://s3.amazonaws.com/underwoods/gift-registry.jpg" alt ="butterfly pendent" />
      <p>Gift Registry »</p>
    </Card>
    </div>
  </Wrap>
  <div css={`display:flex;justify-content:center;text-align:center;color:${colors.green};text-transform:uppercase;padding: 0 0 2rem 0;`}>
    <Link css={`margin: 0 1rem;`} to={`/our-diamond`}>
      <img css={`height:64px;`} src="https://s3.amazonaws.com/underwoods/diamond.png" alt="diamond icon" />
      <h3>The Underwood's Diamond</h3>
    </Link>

    <Link css={`margin: 0 1rem;border-left: 1px solid dimgrey;padding: 0 2rem;`} to={`/diamond-calculator`}>
      <img css={`height:64px;`} src="https://s3.amazonaws.com/underwoods/calculator.png" alt="calculator icon" />
      <h3>Diamond Calculator</h3>
    </Link>
  </div>
  </div>
)

const Wrap = styled.div`
  padding:3rem;
  color: ${colors.green};
  text-align:center;

  ${md} {
    padding: 1rem;
  }
`

export const Card = styled(Link)`
  margin:2rem;
  min-width: 250px;
  width: 30%;
  text-transform: uppercase;

  img {
    width:100%;
    margin: 0;
  }

  p {
    background-color: ${props => props.color ? props.color : colors.green};
    color:white;
    padding:2rem;
    margin:0;
    margin-top:-5px;
  }

  ${md} {
    margin:1rem;
    margin:auto;
    width:80%;

    p {
      margin-bottom:1rem;
    }
  }
`
