import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Contact from '../components/contactForm'
import Input from '../components/input'
import JoinUs from '../sections/joinUs'
import Baby from '../sections/baby'
import Fashion from '../sections/fash-fashion-designers'
import Mens from '../sections/fash-mens-designers'
import { Special } from './index'

import { PageHead, GreyBtn, md, colors } from '../common'

export default ({data}) => (
  <Layout headImg={"https://s3.amazonaws.com/underwoods/header-fashion.jpg"}>
    <SEO
      title="Fashion"
      description="Underwood’s in Jacksonville and Ponte Vedra, FL carry the top fashion jewelry designers in the World. From David Yurman, Roberto Coin, Marco Bicego, Mikimoto, Erica Courtney and many more!" />
    <PageHead>
      <h1>FASHION</h1>
      <p>Underwood’s is proud to carry many jewelry brands and fashion designers in all of our locations in Jacksonville and Ponte Vedra Beach, FL. Our designers include David Yurman, John Hardy, Roberto Coin, Lagos, Monica Rich Kosann, Mikimoto and many more.</p>
      <div css={`display:flex;justify-content:center;${md}{display:flex;flex-direction:column;}`}>
      <div css={`margin:5px;`}>
        <Link to={'/fashion#fashion-designers'}><GreyBtn>Designers</GreyBtn></Link>
      </div>
      <div css={`margin:5px;`}>
        <Link to={'/baby'}><GreyBtn>Baby Jewelry</GreyBtn></Link>
        </div>
        <div css={`margin:5px;`}>
        <Link to={'/fashion#mens-jewelry'}><GreyBtn>Men's Jewelry</GreyBtn></Link>
      </div>
      </div>
    </PageHead>
    <Special data={data}/>
    <div id="fashion-designers" />
    <Fashion />
    <Baby />
    <div id="mens-jewelry" />
    <Mens />
    <div css={`padding:2rem;`}>
      <h2 css={`text-align:center;color:${colors.green}`}>LOOKING FOR SOMETHING SPECIFIC FROM YOUR FAVORITE DESIGNER?</h2>
      <div css={`width:50%;margin:auto;${md}{width:100%;}`}>
      <Contact>
        <Input name="designer" placeholder="Underwoods" />
      </Contact>
      </div>
    </div>
    <JoinUs />
  </Layout>
)

export const Specialz2Query = graphql`
  query Specialz2BarQuery {
    postgres {
      nodeId
      allProductsItems(condition: {category: "special"}, first: 1, orderBy: NATURAL) {
        edges {
          node {
            price
            title
            sku
            image
          }
        }
      }
    }
  }
`
