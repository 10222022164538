import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

const Alert = () => {
  return(
    <div>
      <Wrap>
        <P>
          <a href='https://shop.underwoodjewelers.com/' css={`text-decoration:underline;`} target="_blank">Shop Online</a> 
        </P>
      </Wrap>
    </div>
  )
}

const Wrap = styled.div`
  background-size: cover;
  border-bottom: 8px solid ${colors.green};
  text-align: center;
  display: flex;
  line-height: 1.75;
  margin: 0px

  ${md} {
    height:auto;
    width: 100%;
    background-position:center;
  }
`


const P = styled.div`
  margin-left: auto; 
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  ${md} {
    padding-left 10px;
    padding-right 10px;
  }
`
export default Alert
