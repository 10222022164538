import React from 'react'
import FormWrap, { handleSubmit } from './formWrap'
import Input, { Label } from './input'
import { GreyBtn, md } from "../common"

export default ({ children, btnName = 'Submit Inquiry', location }) => {

  return(
  <FormWrap location={location}>
    <div css={`display:flex;flex-direction:column;text-align:left;overflow:scroll;${md}{margin-bottom:4rem;}`}>
      <input name="form_name" value="contact form" readOnly hidden />
      <Input type="text" name="first_name" placeholder="Herbert" required />
      <Input type="text" name="last_name" placeholder="Underwood" required />
      <Input type="email" name="email" placeholder="email@address.com" required />
      <Input type="text" name="phone" placeholder="(123) 456-7980" />
      {children}
      <Label htmlFor="message">Message</Label>
      <textarea rows="5" name="message" placeholder="message"></textarea>
      <br />
      <div css={`display:flex;margin-bottom:10px;`}>
        <input type="checkbox" defaultChecked name="subscribe"/>
        <Label htmlFor="subscribe">Join our Mailing List</Label>
      </div>
      <GreyBtn type="submit" onClick={handleSubmit}>{btnName}</GreyBtn>
    </div>
  </FormWrap>
)}
