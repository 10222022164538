import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import './carousel.css'
import { md } from '../common'

export default () => {
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 3,
      focusOnSelect: false,
      arrows: true
    }
  return(
  <StaticQuery
    query={graphql`
      query EngaementLogoQuery {
        allDesignersJson(filter: {categories: {regex: "/Fashion/"}}) {
          edges {
            node {
              logo
              name
            }
          }
        }
      }
      `}
      render={ data => (
        <div css={`padding:4rem;text-align:center;text-transform:uppercase;${md}{display:none}`}>
        <Slider {...settings} >
          {data.allDesignersJson.edges.map((designer, index) => {
            return  <div key={index}>
                    <div css={`display:flex;justify-content:center;min-height:200px;`}>
                        <Link css={`padding:1rem;margin:auto;`} to={`/designer/${designer.node.name}`}><img css={`width:90%;`} src={designer.node.logo} alt={designer.node.name} /></Link>
                    </div>
                  </div>
          })}

        </Slider>
        <Link to="/engagement#eng-designers" css={`margin-top:2rem;`}>View All Engagement Designers »</Link>
        </div>
      )}
  />
)}
