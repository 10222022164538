import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GreyBtn, md } from '../common'

export default () => {
  return(
    <Wrap>
      <A to="/designer/forevermark">
        <img css={`width:16rem;margin:20px auto;`} src="https://s3.amazonaws.com/underwoods/_designers/forevermark/logo.png" alt="forvermark logo" />
        <br />
        <GreyBtn style={{maxWidth:'200px',margin:'0 auto'}}>View More about forevermark</GreyBtn>
      </A>
    </Wrap>
  )
}

const A = styled(Link)`
  margin-right:auto;
  width:40%;
  padding:4rem;
  text-transform:uppercase;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:center;

  ${md} {
    margin: 0;
    width: 100%;
    padding:0;
    background-color: rgba(255,255,255,.5);
  }
`

const Wrap = styled.div`
  background-image: url("https://s3.amazonaws.com/underwoods/_designers/forevermark/forevermarkbanner.png");
  background-size: cover;
  background-position: left center;
  min-height: 50vh;
  display:flex;
  margin:auto;

  ${md} {
    background-position: right center;
  }
`
