import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => {
  return(
    <div css={`padding:0 0 2rem 3rem;display:flex;${md}{width:100%;padding:0;justify-content:center;}`}>
      <SocialLink aria-label="link to underwood's facebook page" href="https://www.facebook.com/UnderwoodJewelers" target="_blank" rel="noreferrer"><svg css={`fill:${colors.white};padding:5px;`} xmlns="http://www.w3.org/2000/svg" width="96" viewBox="0 0 96.1 96.1"><path d="M72.1 0L59.6 0C45.6 0 36.6 9.3 36.6 23.7v10.9H24c-1.1 0-2 0.9-2 2v15.8c0 1.1 0.9 2 2 2h12.5v39.9c0 1.1 0.9 2 2 2h16.4c1.1 0 2-0.9 2-2V54.3h14.7c1.1 0 2-0.9 2-2l0-15.8c0-0.5-0.2-1-0.6-1.4 -0.4-0.4-0.9-0.6-1.4-0.6H56.8v-9.2c0-4.4 1.1-6.7 6.8-6.7l8.4 0c1.1 0 2-0.9 2-2V2C74 0.9 73.2 0 72.1 0z"/></svg></SocialLink>
      <SocialLink aria-label="link to underwood's instagram page" href="https://instagram.com/underwood_jewelers/" target="_blank" rel="noreferrer"><svg css={`fill:${colors.white};padding:5px;`} xmlns="http://www.w3.org/2000/svg" width="169" viewBox="0 0 169.1 169.1"><path d="M122.4 0H46.7C20.9 0 0 20.9 0 46.7v75.8c0 25.7 20.9 46.7 46.7 46.7h75.8c25.7 0 46.7-20.9 46.7-46.7V46.7C169.1 20.9 148.1 0 122.4 0zM154.1 122.4c0 17.5-14.2 31.7-31.7 31.7H46.7C29.2 154.1 15 139.9 15 122.4V46.7C15 29.2 29.2 15 46.7 15h75.8c17.5 0 31.7 14.2 31.7 31.7V122.4z"/><path d="M84.5 41c-24 0-43.6 19.5-43.6 43.6 0 24 19.5 43.6 43.6 43.6s43.6-19.5 43.6-43.6C128.1 60.5 108.6 41 84.5 41zM84.5 113.1c-15.7 0-28.6-12.8-28.6-28.6 0-15.7 12.8-28.6 28.6-28.6s28.6 12.8 28.6 28.6C113.1 100.3 100.3 113.1 84.5 113.1z"/><path d="M129.9 28.3c-2.9 0-5.7 1.2-7.8 3.2 -2.1 2-3.2 4.9-3.2 7.8 0 2.9 1.2 5.7 3.2 7.8 2 2 4.9 3.2 7.8 3.2 2.9 0 5.7-1.2 7.8-3.2 2.1-2 3.2-4.9 3.2-7.8 0-2.9-1.2-5.7-3.2-7.8C135.7 29.4 132.8 28.3 129.9 28.3z"/></svg>
      </SocialLink>
      <SocialLink aria-label="link to underwood's twitter page" href="https://twitter.com/UnderwoodsJax" target="_blank" rel="noreferrer">
        <svg css={`fill:${colors.white};width:20px;padding:5px;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"><path d="M302.973 57.388a117.512 117.512 0 0 1-14.993 5.463 66.276 66.276 0 0 0 13.494-23.73 5 5 0 0 0-7.313-5.824 117.994 117.994 0 0 1-34.878 13.783c-12.381-12.098-29.197-18.983-46.581-18.983-36.695 0-66.549 29.853-66.549 66.547 0 2.89.183 5.764.545 8.598C101.163 99.244 58.83 76.863 29.76 41.204a5.001 5.001 0 0 0-8.196.642c-5.896 10.117-9.013 21.688-9.013 33.461 0 16.035 5.725 31.249 15.838 43.137a56.37 56.37 0 0 1-8.907-3.977 5 5 0 0 0-7.427 4.257c-.007.295-.007.59-.007.889 0 23.935 12.882 45.484 32.577 57.229a57.372 57.372 0 0 1-5.063-.735 4.998 4.998 0 0 0-5.699 6.437c7.29 22.76 26.059 39.501 48.749 44.605-18.819 11.787-40.34 17.961-62.932 17.961a120.4 120.4 0 0 1-14.095-.826 5 5 0 0 0-3.286 9.174c29.023 18.609 62.582 28.445 97.047 28.445 67.754 0 110.139-31.95 133.764-58.753 29.46-33.421 46.356-77.658 46.356-121.367 0-1.826-.028-3.67-.084-5.508 11.623-8.757 21.63-19.355 29.773-31.536a5 5 0 0 0-6.182-7.351z"/></svg>
      </SocialLink>
      <SocialLink aria-label="link to underwood's linked in page" href="https://www.linkedin.com/company/underwood-jewelers/about/?viewAsMember=true" target="_blank" rel="noreferrer">
        <svg css={`fill:${colors.white};width:20px;padding:5px;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"><path d="M72.16 99.73H9.927a5 5 0 0 0-5 5v199.928a5 5 0 0 0 5 5H72.16a5 5 0 0 0 5-5V104.73a5 5 0 0 0-5-5zM41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341zM230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73a5 5 0 0 0-5-5h-59.599a5 5 0 0 0-5 5v199.928a5 5 0 0 0 5 5h62.097a5 5 0 0 0 5-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204a5 5 0 0 0 5 5H305a5 5 0 0 0 5-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"/></svg>
      </SocialLink>
      <SocialLink aria-label="link to underwood's youtube page" href="https://www.youtube.com/channel/UCkAvPcaa6AbuXl0hgk50KxQ" target="_blank" rel="noreferrer">
        <svg css={`fill:${colors.white};width:20px;padding:5px;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"><path d="M297.917 64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359 0-61.369 5.776-72.517 19.938C0 79.663 0 100.008 0 128.166v53.669c0 54.551 12.896 82.248 83.386 82.248h143.226c34.216 0 53.176-4.788 65.442-16.527C304.633 235.518 310 215.863 310 181.835v-53.669c0-29.695-.841-50.16-12.083-63.521zm-98.896 97.765l-65.038 33.991a9.997 9.997 0 0 1-14.632-8.863v-67.764a10 10 0 0 1 14.609-8.874l65.038 33.772a10 10 0 0 1 .023 17.738z"/></svg>
      </SocialLink>
      <SocialLink aria-label="link to underwood's pintrest page" href="https://www.pinterest.com/underjax/" target="_blank" rel="noreferrer">
        <svg css={`fill:${colors.white};width:20px;padding:5px;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310.05 310.05"><path d="M245.265 31.772C223.923 11.284 194.388 0 162.101 0c-49.32 0-79.654 20.217-96.416 37.176-20.658 20.9-32.504 48.651-32.504 76.139 0 34.513 14.436 61.003 38.611 70.858 1.623.665 3.256 1 4.857 1 5.1 0 9.141-3.337 10.541-8.69.816-3.071 2.707-10.647 3.529-13.936 1.76-6.495.338-9.619-3.5-14.142-6.992-8.273-10.248-18.056-10.248-30.788 0-37.818 28.16-78.011 80.352-78.011 41.412 0 67.137 23.537 67.137 61.425 0 23.909-5.15 46.051-14.504 62.35-6.5 11.325-17.93 24.825-35.477 24.825-7.588 0-14.404-3.117-18.705-8.551-4.063-5.137-5.402-11.773-3.768-18.689 1.846-7.814 4.363-15.965 6.799-23.845 4.443-14.392 8.643-27.985 8.643-38.83 0-18.55-11.404-31.014-28.375-31.014-21.568 0-38.465 21.906-38.465 49.871 0 13.715 3.645 23.973 5.295 27.912-2.717 11.512-18.865 79.953-21.928 92.859-1.771 7.534-12.44 67.039 5.219 71.784 19.841 5.331 37.576-52.623 39.381-59.172 1.463-5.326 6.582-25.465 9.719-37.845 9.578 9.226 25 15.463 40.006 15.463 28.289 0 53.73-12.73 71.637-35.843 17.367-22.418 26.932-53.664 26.932-87.978 0-26.826-11.52-53.272-31.604-72.556z"/></svg>
      </SocialLink>
      <SocialLink aria-label="link to underwood's yelp page" href="https://www.yelp.com/biz/underwood-jewelers-jacksonville" target="_blank" rel="noreferrer">
      <div css={`height:30px;margin:auto;display:flex;justify-content:center;flex-direction:column;`}>
        <svg css={`fill:${colors.white};width:20px;margin:auto;padding:5px;`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.2,15.46c.49-.21,1.3-.56,1.3-1.46s-.82-1.25-1.3-1.46l-.72-.29c-.76-.31-1.8-.73-2.76-1.19C4.24,10.33,3,11.08,3,12.5v3a1.6,1.6,0,0,0,1.58,1.75,2.62,2.62,0,0,0,1.14-.3c1-.47,2-.89,2.76-1.19Z" style={{fill:"#fff"}}/><path d="M12.68,18.77c0-.32,0-.58-.06-.78,0-.53-.13-1.41-1-1.69s-1.33.26-1.79.79l-.5.59c-.53.63-1.25,1.49-2,2.25a1.94,1.94,0,0,0-.68,1.85A1.73,1.73,0,0,0,7.89,23l2.85.93a2.12,2.12,0,0,0,.65.11,1.54,1.54,0,0,0,1.19-.51A2.08,2.08,0,0,0,13,21.75C12.82,20.7,12.74,19.58,12.68,18.77Z" style={{fill:"#fff"}}/><path d="M19.26,16c-1.05-.18-2.14-.46-2.93-.65l-.76-.18a3.68,3.68,0,0,0-.82-.12,1.26,1.26,0,0,0-1.09.53,1.7,1.7,0,0,0,.2,1.95l.41.66c.43.69,1,1.64,1.53,2.59A1.9,1.9,0,0,0,17.45,22a1.76,1.76,0,0,0,1.4-.79l1.76-2.43a1.75,1.75,0,0,0,.28-1.67A1.94,1.94,0,0,0,19.26,16Z" style={{fill:"#fff"}}/><path d="M14.75,13.94a3.68,3.68,0,0,0,.82-.12l.76-.18c.79-.2,1.88-.47,2.93-.65a1.94,1.94,0,0,0,1.63-1.1,1.75,1.75,0,0,0-.28-1.67L18.85,7.79A1.76,1.76,0,0,0,17.45,7,1.9,1.9,0,0,0,15.8,8.22c-.5.94-1.1,1.89-1.53,2.59l-.41.66a1.7,1.7,0,0,0-.2,1.95A1.26,1.26,0,0,0,14.75,13.94Z" style={{fill:"#fff"}}/><path d="M9.73,11.3a1.5,1.5,0,0,0,2.77-.88l-.5-9A1.49,1.49,0,0,0,11.41.3,1.51,1.51,0,0,0,10.18,0L5.67,1a1.5,1.5,0,0,0-.95,2.26Z" style={{fill:"#fff"}}/></svg>
        </div>
      </SocialLink>
    </div>
  )
}

const SocialLink = styled.a`
  margin: 0 .25rem;
  background-color: ${colors.green};
  border-radius: 5px;
  width:30px;
  height: 30px;
  display:flex;
  justify-content: center;
`
