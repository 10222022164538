import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'
import "./layout.css"

import Alert from '../sections/alert'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, headImg, headSize = 'small' }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Alert />
        <Header headImg={headImg} headSize={headSize} />
          <main>
            {children}
          </main>
        <Footer />
        <script type="text/javascript" src="//cdn.callrail.com/companies/631048734/eeb7ecfc6b4021f1d171/12/swap.js" />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
