import React from "react"
import styled from "styled-components"
// import { Link } from 'gatsby'
import { colors, RolexBannerBtn, md } from "../common"

const RolexBar = () => {
  return (
    <div>
      <a href="/rolex/" alt="Rolex">
        <Wrap>
          <DesktopImg src="https://s3.amazonaws.com/underwoods/_designers/rolex/rolex-monthly-desktop-full.jpg" />
          <MobileImg src="https://s3.amazonaws.com/underwoods/_designers/rolex/rolex-monthly-mobile-full.jpg" />
        </Wrap>
      </a>
    </div>
  )
}

export default RolexBar

const Wrap = styled.div`
  border-top: 8px solid ${colors.green};
  border-bottom: 8px solid ${colors.green};
  text-align: center;

  ${md} {
    border-bottom: none;
  }
`

const DesktopImg = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -5px;

  ${md} {
    display: none;
  }
`

const MobileImg = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -5px;
  padding-bottom: 1rem;
  display: none;

  ${md} {
    display: block
  }
`