import React from "react"
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { colors, GreyBtn, md } from '../common'

import Layout from "../components/layout"
import SEO from "../components/seo"
import RolexBar from '../sections/rolexBar'
// import DavidYurmanBar from '../sections/davidYurmanBar'
// import JoinUs from '../sections/joinUs'
import Locations from '../sections/locations'
import EngagementCarousel from '../sections/home-engagementCarousel'
import ForevermarkBar from '../sections/forevermarkBar'
import Engaged from '../sections/home-engaged'
// import Quote from '../sections/quote'
import Insta from '../sections/home-insta-carousel'

export default ({data}) => {

  return(
  <Layout
    headImg={`https://s3.amazonaws.com/underwoods/homepage-header.jpg`}
    headMobileImg={`https://s3.amazonaws.com/underwoods/homepage-header_m.jpg`}
    headSize="medium"
  >
    <SEO title="Jacksonville's Official Rolex & Forevermark Jeweler" />
    <RolexBar />
    {/* <DavidYurmanBar /> */}
    <Engaged />
    <Special data={data}/>
    <Insta />
    <ForevermarkBar />
    <EngagementCarousel />
    <Locations>
      <h1 css={`text-transform:uppercase;color:${colors.green};`}>Locations & Hours</h1>
    </Locations>
  </Layout>
)}

export const Special = ({data}) => {
  const special = data.postgres.allProductsItems.edges[0].node
  return(
    <div css={`background-color:${colors.green};color:white;display:flex;${md}{flex-direction:column;padding:1rem;}`}>
    <Copy css={`font-weight:800;`}>
      <h1>Specials</h1>
      <p>Every month Underwood’s introduces a new $100 Special which comes in limited quantities. <br />These specials are beautiful jewelry designs of all types and are the perfect gift for any occasion.</p>
      <p>Buy online and pick up in store.</p>
      <br />
      <Link to="/products/specials"><p>Shop $100 Specials</p></Link>
    </Copy>
    <div css={`display:flex;background-color:#416834;padding:2rem;text-transform:uppercase;margin:2rem;${md}{flex-direction:column;}`}>
      <div>
        <img css={`max-width:250px;width:100%;`} src={special.image} alt={special.title} />
      </div>
      <div css={`padding:2rem;margin:auto;${md}{padding:0;}`}>
        <h2>{special.title}</h2>
        <p>Item No. {special.sku}</p>
        <p>${special.price}</p>
        <Link to="/products/specials"><GreyBtn>Shop Our Specials</GreyBtn></Link>
      </div>
    </div>
    </div>
  )
}

export const SpecialzQuery = graphql`
  query SpecialzBarQuery {
    postgres {
      nodeId
      allProductsItems(condition: {category: "special"}, first: 1, orderBy: NATURAL) {
        edges {
          node {
            price
            title
            sku
            image
          }
        }
      }
    }
  }
`

const Copy = styled.div`
  width:50%;
  padding: 2rem;
  text-align:center;
  margin:auto;
  border-right: 1 px solid white;
  margin:2rem;
  background-image:url('https://s3.amazonaws.com/underwoods/icon_services.png');
  background-position:center;
  background-repeat:no-repeat;

  ${md} {
    width:100%;
    padding:0;
    margin:0;
  }
`
