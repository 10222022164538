const items =  [
    { "path": "/engagement", "title": "engagement" },
    { "path": "/wedding-anniversary", "title": "wedding & anniversary" },
    { "path": "/fashion", "title": "fashion" },
    { "path": "/services#custom-jewelry", "title": "Custom Designs" },
    { "path": "/watches", "title": "watches" },
    { "path": "/rolex/", "title": "Rolex" },
    { "path": "/registry", "title": "gift registry" },
    { "path": "/engagement#grow-diamond", "title": "Grow a Diamond" },
    { "path": "/about-us", "title": "about" },
    { "path": "/our-diamond", "title": "our diamond" },
    { "path": "/diamond-calculator", "title": "diamond calculator" },
    { "path": "/trading-selling", "title": "trading in & up" },
    { "path": "/returns", "title": "returns" },
    { "path": "/services", "title": "services" },
    { "path": "/financing", "title": "financing" },
    { "path": "/faq", "title": "FAQ" },
    { "path": "/shipping", "title": "shipping" },
    { "path": "/privacy-policy", "title": "privacy policy" },
    { "path": "/terms-of-service", "title": "terms of service" },
    { "path": "http://teams.underwoodjewelers.com/", "title": "employee login" }
  ]

export default items
