import { Location } from '@reach/router'
import React from 'react'

const currentSite = 'underwoods'
const defaultHTML = `<div style="text-align:center;"><img style="max-width:200px;padding-bottom:50px;" src="https://s3.amazonaws.com/underwoods/logo.png" alt="logo" ></div>`
const list_id = '7e9aab57ea'

export function handleSubmit() {
    if (typeof window !== "undefined") {
      const formElements = document.querySelectorAll('.bill')
      const elementsArray = Array.prototype.slice.call(formElements)
      elementsArray.forEach(form => {
        const inputElement = document.createElement('input')
        inputElement.setAttribute('type','hidden')
        inputElement.setAttribute('name','valid_token')
        inputElement.setAttribute('value', window.localStorage.getItem('being_keys') )
        form.appendChild(inputElement)
      })
    }
  }

export default ({ children, html = defaultHTML }) => {

  return(
    <Location>
      {({ location }) => {
        return  <form action="http://helixappform.us-east-2.elasticbeanstalk.com/index.php" method="POST" className="bill">
                  <input name="_sendgrid_notification" readOnly value={html} hidden/>
                  <input name="helix_referral" readOnly value={currentSite} hidden/>
                  <input name="*redirect" readOnly value={`${location.origin}/thank-you`} hidden/>
                  <input name="_mailchimp_subscribe" value={list_id} hidden readOnly />
                  {children}
                  <input name="page" value={location.href} readOnly hidden />
                </form>
      }}
    </Location>
)}
