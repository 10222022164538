import styled from 'styled-components'
import React from 'react'
import { Link } from 'gatsby'
import { colors, md } from '../common'

export default ({ children, title }) => (
  <div css={`background-color: ${colors.greyLight};padding: 4rem;text-align:center;${md}{padding: 1rem;}`}>
    <h1 css={`display:${ title ? 'block' : 'none'};color:${colors.green};text-transform:uppercase;margin:0 0 4rem 0;`}>{title}</h1>
    <div css={`display:flex;flex-wrap: wrap;justify-content:flex-start;`}>
    {children}
    </div>
  </div>
)

export const Card = ({ designer, designerName, theId }) => (
  <DesignCard id={theId}>
    <div>
      <Link to={designer.node.name === 'Underwoods' ? `/products/engagement` : designer.node.name === 'Rolex' ? `/rolex/` : `/designer/${designerName ? designerName : designer.node.name}`}>
        <img css={`width:100%;`} src={designer.node.category_image} alt={`${designer.node.name} logo`} />
      </Link>

      <div css={`position: relative;padding: 2rem;display: flex;flex-direction: column;-moz-box-pack: justify;justify-content: space-between;-moz-box-flex: initial;flex-grow: initial;`}>
        <p css={`color: rgb(76, 117, 61);font-size: 20px;text-transform: uppercase;`}>{designer.node.name}</p>
        <div css={`text-align: center;font-size: 14px;color: rgb(62, 62, 62);line-height: 20px;`}>{designer.node.description}</div>
      </div>
    </div>
  </DesignCard>
)

export const DesignCard = styled.div`
  width: calc(25% - 1rem);
  text-align:center;
  margin: .5rem;
  background-color: white;
  font-size:14px;
  color: ${colors.greyDarkest};
  line-height: 20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  ${md} {
    width:100%;
  }
`
