import styled from 'styled-components'

export const colors = {
  green: '#4c753d',
  greenDarkest: '#032d19',
  greenDarker: '#0b4228',
  greenDark: '#416834',
  greenRolex: '#127749',
  black: '#222b2f',
  mediumBlack: '#353535',
  greyDarkest: '#3e3e3e',
  greyDarker: '#596a73',
  greyDark: '#70818a',
  grey: '#808080',
  greyLight: '#edf1eb',
  greyLighter: '#f3f7f9',
  greyLightest: '#fafcfc',
  white: "#fff"
}

export const md = `@media(max-width:768px)`

const Btn = styled.button`
  border:0;
  padding:.5rem 1rem;
  color:white;
  text-transform:uppercase;
  cursor: pointer;
`

export const GreenBtn = styled(Btn)`
  background-color:${colors.green};
`

export const GreyBtn = styled(Btn)`
  background:${colors.greyDarkest}
`

export const RolexBannerBtn = styled(Btn)`
  background-color:${colors.greenRolex};
`

export const PageHead = styled.div`
  padding: 3rem;
  text-align:center;
  line-height: 24px;

  h1 {
    color:${colors.green};
    text-transform:uppercase;
    letter-spacing:1.6px;
    line-height: 32px;
  }

  p {
    color:${colors.greyDarker};
  }

  ${md} {
    padding:2rem;
  }
`

export const Copy = styled.div`
  width:40%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`
