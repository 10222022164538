import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import './carousel.css'
import { md, colors } from '../common'

export default () => {
  const settings = {
      dots: false,
      infinite: true,
      speed: 800,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 3,
      focusOnSelect: false,
      arrows: false
    }
  return(
  <StaticQuery
    query={graphql`
      query InstaQuery {
        allInstaNode {
          edges {
            node {
              original
            }
          }
        }
      }
      `}
      render={ data => (
        <div css={`padding:3rem;text-align:center;text-transform:uppercase;${md}{display:none}`}>
        <a href="https://www.instagram.com/underwood_jewelers/" target="_blank" rel="noopener noreferrer"><h2 css={`color:${colors.green}`}>Underwood's on Instagram</h2></a>
        <Slider {...settings}>
          {data.allInstaNode.edges.map((designer, index) => {
            return  <div key={index} css={`margin:1rem;`}><div css={`background-image:url(${designer.node.original});background-position:center;background-size:contain;background-repeat:no-repeat;max-height:500px;max-width:500px;min-width:200px;min-height:400px;margin:.5rem;`}></div></div>
          })}
        </Slider>
        </div>
      )}
  />
)}
