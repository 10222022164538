import React, { useState } from 'react'
import { StaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../common'

export default ({ toggle }) => {
  const [ sub, setSub ] = useState('')
  function EnagementClick() {
    setSub('Engagement')
  }
  function WeddingClick() {
    setSub('Wedding & Anniversary')
  }
  function FashionClick() {
    setSub('Fashion')
  }
  function WatchesClick() {
    setSub('Watches')
  }
  function ServicesClick() {
    setSub('Services')
  }
  function AboutClick() {
    setSub('About')
  }
  function RolexClick() {
    setSub('Rolex')
  }

  return(
    <Wrap toggle={toggle}>
    <div css={`display:flex;`}>
      <Right css={`width:50%;background-color:${colors.green};color:white;`}>
        <p onClick={EnagementClick}>Engagement</p>
        <p onClick={WeddingClick}>Wedding & Anniversary</p>
        <p onClick={FashionClick}>Fashion</p>
        <p onClick={WatchesClick}>Watches</p>
        <p><a href="/rolex/">Rolex</a></p>
        <p onClick={ServicesClick}>Services</p>
        <p onClick={AboutClick}>About</p>
      </Right>

      <Left css={`width:50%;background-color:white;color:${colors.green};`}>
      <StaticQuery
        query={graphql`
          query MobileMenuQuery {
            allHeaderMenuJson {
              edges {
                node {
                  title
                  path
                  illustration {
                    image
                    link
                    path
                    title
                  }
                  submenu {
                    path
                    title
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.allHeaderMenuJson.edges.filter(i => i.node.title === sub).map((node, index) => {
              return <ChildMenu key={index} data={node.node.submenu} titleNav={node.node}/>
            })}
          </div>
        )}
      />
      </Left>
      </div>

      <Bottom>
        <Side css={`display:flex;justify-content:space-between;`}>
        <Link css={`margin:auto;`} to="/locations"><svg css={`width:30px;fill:${colors.green};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><path d="M38.9 5.3L38.9 5.3c-7.1-7.1-18.6-7.1-25.7 0h0C6.8 11.7 6 23.8 11.5 31L26 52l14.5-21C46 23.8 45.2 11.7 38.9 5.3zM26.2 24c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6S29.5 24 26.2 24z" /></svg></Link>
        <Link css={`margin:auto;`} to="/cart"><svg css={`width:30px;fill:${colors.green};`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510"><path d="M153 408c-28 0-51 23-51 51s23 51 51 51 51-22.9 51-51S181.1 408 153 408zM0 0v51h51l91.8 193.8L107.1 306c-2.5 7.7-5.1 17.9-5.1 25.5 0 28.1 23 51 51 51h306v-51H163.2c-2.5 0-5.1-2.5-5.1-5.1v-2.6l23-43.3h188.7c20.4 0 35.7-10.2 43.4-25.5L504.9 89.3c5.1-5.1 5.1-7.6 5.1-12.7 0-15.3-10.2-25.5-25.5-25.5H107.1L84.2 0H0zM408 408c-28 0-51 23-51 51s23 51 51 51 51-22.9 51-51S436.1 408 408 408z"/></svg></Link>
        </Side>
        <Side css={`display:flex;justify-content:center;`}>
          <Link to="/rolex/" style={{ pointerEvents: 'initial' }}>
            <iframe style={{ border:'0', width: '150px', height: '80px', pointerEvents: 'none' }} title="rolex-clock" src="https://static.rolex.com/retailers/clock/?colour=silver&apiKey=c8181a49983723edacb987b3682aca31&lang=en_us" />
          </Link>
        </Side>
      </Bottom>
    </Wrap>
  )
}

const ChildMenu = ({data, titleNav}) => {
  if (data !== null) {
    return(
      <div css={`display:flex;flex-direction:column;`}>
      <Link css={`padding:.5rem;`} to={titleNav.path}>{titleNav.title}</Link>
      {data.map((m, index) => {
        return <Link css={`padding:.5rem;`} to={m.path} key={index}>{m.title}</Link>
      })}
      </div>
    )
  }
  else {
    return(
      <div css={`padding:.5rem;`}>
      <Link to="/rolex/">Rolex</Link>
      </div>
    )
  }
}

const Wrap = styled.div`
  display: ${ props => props.toggle ? 'block' : 'none' };
  position: absolute;
  width:100%;
  border-top: 2px solid ${colors.green};
`

const Side = styled.div`
  width:50%;
  padding:1rem;
`

const Right = styled(Side)`
  background-color:${colors.green};
  color: white;
`

const Left = styled(Side)`
  background-color:white;
  color: ${colors.green};
`

const Bottom = styled.div`
  background-color:white;
  display:flex;
  border-top: 2px solid ${colors.green};
  border-bottom: 2px solid ${colors.green};
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`
