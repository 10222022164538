import React, { useState } from "react"
import { StaticQuery, Link } from 'gatsby'
import { md, colors } from '../common'
import styled from 'styled-components'
import MobileNav from './mobileNav'

export default ({
  headImg = "https://s3.amazonaws.com/underwoods/header_img.jpg",
  headSize,
  headMobileImg = "https://s3.amazonaws.com/underwoods/homepage-header_m.jpg"
  }) => {

  const [ sub, setSub ] = useState('')
  const [ mobile, setMobile ] = useState(false)

  function EnagementClick() {
    setSub('Engagement')
  }
  function WeddingClick() {
    setSub('Wedding & Anniversary')
  }
  function FashionClick() {
    setSub('Fashion')
  }
  function WatchesClick() {
    setSub('Watches')
  }
  function ServicesClick() {
    setSub('Services')
  }
  function AboutClick() {
    setSub('About')
  }
  function CloseClick() {
    setSub('')
  }
  function toggleMobileNav() {
    setMobile(!mobile)
  }

  switch (headSize) {
    case 'full' : headSize = 60
      break
    case 'medium' : headSize = 40
      break
    case 'small' : headSize = 25
      break
    default : headSize = 60
      break
  }
  return(
  <header css={`background-image:url(${headImg});background-size:cover;background-position:center;min-height:250px;height:${headSize}vh;${md}{height:100%;background-image:url(${headMobileImg})};`}>
    <div css={`padding:3rem;display:flex;justify-content:space-between;${md}{padding:0;justify-content:center;}`}>

      <div css={`display:flex;width:200px;${md}{display:none;}`}>
        <a css={`margin:auto;`} target="_blank" href="https://www.tudorwatch.com/" rel="noopener noreferrer"><img  style={{height: '62px', marginTop: '1px'}} src="https://s3.amazonaws.com/underwoods/tudor-header-cta.png" alt="tudor watches badge"/></a>
        <Link css={`margin:auto;`} to="/locations"><svg css={`width:20px;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><path d="M38.9 5.3L38.9 5.3c-7.1-7.1-18.6-7.1-25.7 0h0C6.8 11.7 6 23.8 11.5 31L26 52l14.5-21C46 23.8 45.2 11.7 38.9 5.3zM26.2 24c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6S29.5 24 26.2 24z" /></svg>Locations</Link>
      </div>

      <MobileIcons>
        <img onClick={toggleMobileNav} css={`width:30px;margin:auto;padding:1rem;`} src={`https://s3.amazonaws.com/underwoods/media/mobile${ mobile ? 'Close' : 'Menu'}.png`} alt="menu icon"/>
      </MobileIcons>

      <div css={`text-align:center;display:flex;flex-direction:column;`}>
        <Link to="/"><img css={`max-width:300px;width:100%;${md}{width:70%;padding:1rem;}`} src="https://s3.amazonaws.com/underwoods/logo.png" alt="underwood's logo" /></Link>
        <small css={`margin-top:8px;`}>Member of the American Gem Society</small>
      </div>

      <MobileIcons>
      <a css={`margin:auto;`} className="snipcart-checkout" href="/products#!/cart">
        <svg css={`width:30px;padding:1rem;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510"><path d="M153 408c-28 0-51 23-51 51s23 51 51 51 51-22.9 51-51S181.1 408 153 408zM0 0v51h51l91.8 193.8L107.1 306c-2.5 7.7-5.1 17.9-5.1 25.5 0 28.1 23 51 51 51h306v-51H163.2c-2.5 0-5.1-2.5-5.1-5.1v-2.6l23-43.3h188.7c20.4 0 35.7-10.2 43.4-25.5L504.9 89.3c5.1-5.1 5.1-7.6 5.1-12.7 0-15.3-10.2-25.5-25.5-25.5H107.1L84.2 0H0zM408 408c-28 0-51 23-51 51s23 51 51 51 51-22.9 51-51S436.1 408 408 408z"/></svg>
      </a>
      </MobileIcons>


      <div css={`display: flex; width: 220px; height: 100%; overflow: hidden; ${md}{display:none;}`}>
        <a css={`margin:auto;`} className="snipcart-checkout" href="/products#!/cart">
          <svg css={`width:20px;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510"><path d="M153 408c-28 0-51 23-51 51s23 51 51 51 51-22.9 51-51S181.1 408 153 408zM0 0v51h51l91.8 193.8L107.1 306c-2.5 7.7-5.1 17.9-5.1 25.5 0 28.1 23 51 51 51h306v-51H163.2c-2.5 0-5.1-2.5-5.1-5.1v-2.6l23-43.3h188.7c20.4 0 35.7-10.2 43.4-25.5L504.9 89.3c5.1-5.1 5.1-7.6 5.1-12.7 0-15.3-10.2-25.5-25.5-25.5H107.1L84.2 0H0zM408 408c-28 0-51 23-51 51s23 51 51 51 51-22.9 51-51S436.1 408 408 408z"/></svg>
          CART
        </a>
        
        <Link style={{ width: '150px', height: '70px'}} to="/rolex/">
            <iframe style={{ border:'0', padding: 0, margin: 0, width: '150px', height: '70px', overflow: 'hidden', pointerEvents: 'none' }} title="rolex-clock" scrolling="no" frameborder="no" src="https://static.rolex.com/retailers/clock/?colour=silver&apiKey=c8181a49983723edacb987b3682aca31&lang=en_us" />
        </Link>
      </div>
    </div>

    <MobileNav toggle={mobile} />

    <div>
    <div css={`display: flex;justify-content:space-between;width:80%;margin:auto;${md}{display:none;}`}>
      <div>
        <Nav onClick={EnagementClick} onMouseEnter={EnagementClick}>Engagement</Nav>
        <Triangle show={ ( sub === 'Engagement') }/>
      </div>

      <div>
        <Nav onClick={WeddingClick} onMouseEnter={WeddingClick}>Wedding & Anniversary</Nav>
        <Triangle show={ ( sub === 'Wedding & Anniversary') }/>
      </div>

      <div>
        <Nav onClick={FashionClick} onMouseEnter={FashionClick}>Fashion</Nav>
        <Triangle show={ ( sub === 'Fashion') }/>
      </div>

      <div>
        <Nav onClick={WatchesClick} onMouseEnter={WatchesClick}>Watches</Nav>
        <Triangle show={ ( sub === 'Watches') }/>
      </div>

      <div>
        <Nav><a href="/rolex/">Rolex</a></Nav>
        <Triangle />
      </div>

      <div>
        <Nav onClick={ServicesClick} onMouseEnter={ServicesClick}>Services</Nav>
        <Triangle show={ ( sub === 'Services') }/>
      </div>

      <div>
        <Nav onClick={AboutClick} onMouseEnter={AboutClick}>About</Nav>
        <Triangle show={ ( sub === 'About') }/>
      </div>

    </div>
    <StaticQuery
      query={graphql`
        query MenuQuery {
          allHeaderMenuJson {
            edges {
              node {
                title
                path
                illustration {
                  image
                  link
                  path
                  title
                }
                submenu {
                  path
                  title
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          {data.allHeaderMenuJson.edges.filter(i => i.node.title === sub).map((node, index) => {
            return  <SubNav data={node.node.submenu} top={node.node} key={index} close={CloseClick} illustration={node.node.illustration}/>
          })}
        </div>
      )}
    />
    </div>
  </header>
  )
}

const SubNav = ({data, top, close, illustration}) => {
  if (data !== null) {
    return(
      <div onMouseLeave={close} css={`box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);z-index:2;width:80%;background-color:white;margin:auto;border-top:8px solid ${colors.green};position:absolute;left:10%;${md}{display:none;}`}>
      <div css={`display:flex;justify-content:space-between;`}>
      <div css={`padding:2rem;columns:2;column-gap:2rem;font-size:14px;width:100%;`}>
      <div><br css={`${md}{display:none;}`}/>
      <A to={top.path}>{top.title}</A><hr css={`${md}{display:none;}`}/></div>
      {data.map((i, index) => {
            return <div key={index}><br css={`${md}{display:none;}`}/><A to={i.path}>{i.title}</A><hr css={`${md}{display:none;}`}/></div>
          })
        }
        </div>

        <div css={`padding:2rem;border-left:1px solid green;text-align:center;${md}{display:none;}`}>
        <p css={`text-align:right;`} onClick={close}>close (X)</p>
          <img css={`width:200px;`} src={illustration.image} alt={illustration.title} />
          <p css={`text-transform:uppercase;color:${colors.green};`}>{illustration.title}</p>
          <Link css={`font-size:14px;`} to={illustration.path}>{illustration.link}</Link>
        </div>
        </div>
      </div>
    )
  }else{
    return null
  }
}

const Nav = styled.h3`
  text-transform:uppercase;
  text-align:center;
  color:${colors.green};

  ${md}{
    font-size:14px;
    margin:0;
    padding:.5rem 1rem;
  }
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid ${colors.green};
  margin:auto;
  display: ${ props => props.show ? 'block' : 'none'};

  ${md}{
    display: none;
    border:0;
  }
`

const MobileIcons = styled.div`
  display:none;

  ${md}{
    display:block;
  }
`

const A = styled(Link)`

:after {
  content: '»';
  padding-left: 5px;
  display: inline-block;
  transition: 0.3s all ease-in;
}

:hover:after,
:focus:after {
  transform: translateX(5px);
}
`
