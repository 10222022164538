import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CardWrap, { Card } from '../components/designer-cards'

export default () => (
  <StaticQuery
    query={graphql`
      query MensDesignersQuery {
        allDesignersJson(filter: {categories: {regex: "/Mens/"}}, sort: {fields: display_order, order: DESC})  {
          edges {
            node {
              category_image
              description
              display_order
              name
            }
          }
        }
      }
      `}
      render={ data => (
        <CardWrap the-id={'mens-jewelry'} title="Men's Designers">
          {data.allDesignersJson.edges.map((designer, index) => {
            const designerName = designer.node.name === 'Benchmark Wedding Bands' ? 'benchmark' : designer.node.name
            return  <Card key={index} designer={designer} designerName={designerName}/>
          })}
        </CardWrap>
      )}
  />
)
