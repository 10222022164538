import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CardWrap, { Card } from '../components/designer-cards'

export default () => (
  <StaticQuery
    id="fashion-designers"
    query={graphql`
      query FashDesignersQuery {
        allDesignersJson(filter: {categories: {regex: "/Fashion/"}}, sort: {fields: display_order, order: DESC})  {
          edges {
            node {
              category_image
              description
              display_order
              name
            }
          }
        }
      }
      `}
      render={ data => (
        <CardWrap title="Fashion Designers" >
          {data.allDesignersJson.edges.map((designer, index) => {
            const designerName = designer.node.name === 'Roberto Coin' ? 'robertocoin' : designer.node.name === 'Marco Bicego' ? 'marco-bicego' : designer.node.name === 'Penny Preville' ? 'penny-preville' : designer.node.name
            
            return  <Card key={index} designer={designer} designerName={designerName} />
          })}
        </CardWrap>
      )}
  />
)
