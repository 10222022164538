import React, { useState } from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, GreenBtn, md } from '../common'
import items from './footerLinks'
import Modal from './modal'
import Contact from './contactForm'
import Social from './footerSocial'

export default () => {
  const [ show, setShow ] = useState(false)
  const [ mobile, setMobile ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  function toggleMobileNav() {
    setMobile(!mobile)
  }

  return(
    <footer css={`padding-top:3rem;${md}{padding:0;}`}>
    <Top>
      <div css={`width:40%;position:relative;${md}{width:100%;}`}>
      <Nav mobile={mobile}>
        {items.map((link, index) => {
          return <div key={index} css={`padding:.25rem;${md}{padding: .5rem 1rem;}`}>
                    {link.title === 'employee login' ? <B href={link.path} target="_blank" >{link.title}</B> : <A to={link.path}>{link.title}</A>}
                  </div>
        })}
        </Nav>
        <div css={`text-align:center;display:none;${md}{display:block}`}>
          <button onClick={toggleMobileNav} css={`padding:1rem 2rem;text-transform:uppercase;font-size:20px;border:0;background-color:white;margin-bottom:1rem;`}>{ mobile ? 'hide' : 'show'} navigation</button>
        </div>
        <Social />
      </div>

      <div css={`width:20%;${md}{width:100%;}`}>
        <div css={`display:flex;flex-direction:column;justify-content:center;margin:auto;border-left:1px solid black;border-right:1px solid black;${md}{border:0;text-align:center;margin-bottom:2rem;}`}>
          <Store>
            <p>San Marco</p>
            <a href="tel:9043989741">Phone: 904-398-9741</a>
          </Store>
          <Store>
            <p>Avondale</p>
            <a href="tel:9043885406">Phone: 904-388-5406</a>
          </Store>
          <Store>
            <p>Ponte Vedra Beach</p>
            <a href="tel:9042801202">Phone: 904-280-1202</a>
          </Store>
        </div>
      </div>

      <div css={`padding:2rem;width:20%;${md}{width:100%;padding:0;text-align:center;margin-bottom:2rem;}`}>
        <a href="https://www.americangemsociety.org/" target="_blank" rel="noopener noreferrer" ><img css={`width:100%;max-width:200px;`} className="lazy" src="https://s3.amazonaws.com/underwoods/AmericanSociety.png" alt="American Gem Society logo" /></a>
        <div css={ `margin-top:3rem;`}>
          <label htmlFor="contact us button" >Have a Question?</label>
          <GreenBtn css={`margin-left:10px;`} name="contact us button" onClick={handleClick}>contact us</GreenBtn>
        </div>
      </div>
    </Top>

    <Bottom>
      <p>© {new Date().getFullYear()} UNDERWOOD JEWELERS. ALL RIGHTS RESERVED.</p>
    </Bottom>

    <Modal show={ show } toggle={ handleClick } mobile={ true }>
      <Contact>

      </Contact>
    </Modal>
    </footer>
  )
}

const Bottom = styled.div`
  background-color: ${colors.green};
  color: #fff;
  font-size: 10px;
  text-align: center;
  padding: 1.5rem;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  ${md} {
    flex-direction: column;
  }
`

const A = styled(Link)`
  text-transform: uppercase;
  color: ${colors.black};
  font-size: 12px;

  ${md}{
    color:white;
  }
`

const B = styled.a`
  text-transform: uppercase;
  color: ${colors.black};
  font-size: 12px;

  ${md}{
    color:white;
  }
`

const Store = styled.div`
  font-size:11px;
  text-transform:uppercase;
  padding: 2rem;

  p {
    margin:0;
    color:${colors.green};
  }

  a {
    color: black;
    margin:0;
  }

  ${md} {
    padding: 1rem;
  }
`

const Nav = styled.div`
  columns:3;
  padding:3rem;

  ${md}{
    width:100%;
    padding:1rem;
    columns:2;
    background-color:rgba(0, 0, 0, .8);
    display: ${ props => props.mobile ? 'block' : 'none'};
    position:absolute;
    bottom:100px;
  }
`
