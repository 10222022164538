import React from 'react'
// import { Link } from 'gatsby'
import styled from 'styled-components'
//add back in GreenBtn to the 'common' import when uncommenting the button/links
import { md } from '../common'

export default () => {
  return(
  <Wrap>
  <Copy>
    <h1>BABY JEWELRY</h1>
    <p>You are never too young for jewelry or diamonds! Underwood’s carries jewelry and gifts for babies and young children in all varieties. We carry styles such as bangle bracelets, add-a-pearl necklaces, baby earrings and gold necklaces- the perfect gifts for babies and children! We have a Grow Your Diamond program in which you can start off with small diamond stud earrings and trade them in as your child gets older for a larger pair.</p>
    {   // <div >
        //   <Link to={`/products/baby`}><GreenBtn>Shop baby Jewelry</GreenBtn></Link>
        // </div>
    // <div css={`margin: 1rem 0;`}>
    //   <Link to={`/products/add-pearl`}><GreenBtn>Add A Pearl Necklace</GreenBtn></Link>
    // </div>
    // <div >
    //   <Link to={`/engagement#grow-diamond`}><GreenBtn>Grow A Diamond</GreenBtn></Link>
    // </div>
    }
  </Copy>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/baby-jewelry_bg2.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`
