import React from 'react'
import styled from 'styled-components'
import { md } from '../common'

export default ({ children, show, toggle, mobile }) => {
  let height = '100vh'

  if (typeof window !== "undefined") {
    height = `${document.body.scrollHeight}px`
  }

  return(
    <TheModal show={ show } mobile={ mobile } height={height} >
      <MView>
        <CloseBtn onClick={toggle}>X</CloseBtn>
        {children}
      </MView>
      <br />
      <br />
      <br /><br />
    </TheModal>
  )
}

const TheModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${ props => props.height};
  padding-top: 2rem;
  background-color: rgba(0, 0, 0, .8);
  overflow: scroll;
  margin: auto;
  display: ${ props => props.show ? 'block' : 'none' };
  z-index:100;

  button:focus {
    outline:0;
  }

  ${md} {
    display: ${ props => props.show && props.mobile ? 'block' : 'none' };
  }
`

const MView = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 100px;
  margin: auto;
  width: 90vw;
  max-width: 800px;
  overflow:scroll;

  ${md} {
    width: 80%;
    margin-top: 50px;
    margin: auto;
  }
`

const CloseBtn = styled.button`
  border: 0;
  font-size: 40px;
  text-align: right;
  width: 100%;
  font-weight: 700;
  background-color:white;
`
