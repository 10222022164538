import React from 'react'
import styled from 'styled-components'
import { colors } from '../common'

export default ({name, type = 'text', placeholder}) => (
  <div css={`display:flex;flex-direction:column;`}>
    <Label htmlFor={name}>{name}</Label>
    <Input name={name} type={type} placeholder={placeholder} required/>
  </div>
)

export const Input = styled.input`
  padding:.5rem;
  margin: 5px 0;
`

export const Label = styled.label`
  text-transform:uppercase;
  color: ${colors.green};
`
